<template>
    <div class="visitor" @click="hideSetBoxMo()">
        <Like></Like>
        <Hate></Hate>
        <RemoveComment
            v-if="showRemoveComment"
            :musicNm="musicInfo.musicNm"
            @closeModal="showRemoveComment = false"
            @removeComment="removeMusicComment()"
        />
        <header class="header mo_header mo_header_1023" style="background: transparent; backdrop-filter: none">
            <div class="header_box">
                <div class="logo">
                    <a href="curson: pointer;">
                        <img
                            :src="isHyundaiCaptatial ? '/media/img/visitor/hyundai_capital_ci.png' : '/media/img/logo_m.png'"
                            :class="{ hyundai_capital_img: isHyundaiCaptatial }"
                            alt="로고"
                        />
                    </a>
                </div>
                <div class="visitor_shop_info">
                    <img src="/media/img/visitor/header_shop.svg" alt="" />
                    <p v-html="spaceInfo.spaceNm" />
                </div>
            </div>
        </header>
        <div class="music_comment">
            <div class="music_comment_music_info">
                <div class="music_comment_album">
                    <img :src="musicInfo.albumImg" />
                </div>
                <div class="music_comment_musicNm_artist">
                    <p class="music_comment_musicNm" v-html="musicInfo.musicNm"></p>
                    <p class="music_comment_artist" v-html="musicInfo.artist"></p>
                    <div class="visitor_like">
                        <img id="v_like" src="/media/img/like_icon_off.svg" width="24" height="24" @click="changeLike($event)" />
                        <img
                            id="v_unlike"
                            src="/media/img/hate_icon_off.svg"
                            width="24"
                            height="24"
                            @click="changeDisLike($event)"
                        />
                    </div>
                </div>
            </div>
            <div class="visitor_info">좋아요/싫어요 클릭 시 큐레이션에 반영됩니다.</div>
            <div class="visitor_comment">한줄평</div>
            <div class="comment_content visitor" v-if="!updateComment">
                <textarea
                    type="text"
                    maxlength="50"
                    placeholder="이 노래의 한줄평을 남겨보세요:)"
                    v-model="content"
                    @click="textareaClickEvent($event)"
                    v-if="isLogin"
                ></textarea>
                <div class="click_login visitor" v-else @click="textareaClickEvent($event)">
                    이곳을 클릭하여 로그인 후 한줄평을 남겨보세요:)
                </div>
                <p class="comment_length">{{ content.length }}<span>/50</span></p>
                <span class="comment_btn_cancel" @click="insertTextBoxOff($event)">취소</span>
                <span
                    class="comment_btn"
                    :class="{ disabled: disabledInsert === true }"
                    v-if="content.length > 0"
                    @click="insertMusicComment($event)"
                >등록</span
                >
                <span class="comment_btn" style="text-align: center; filter: brightness(60%)" v-else>등록</span>
                <div class="setting_area">
                    <div class="simple_login">
                        <div class="simple_login_title">
                            <h2>로그인</h2>
                            <div class="simple_login_close_btn">
                                <img src="/media/img/close_btn.png" width="13.33" height="13.33" alt="" @click="hideSimpleLoginBox()" />
                            </div>
                        </div>
                        <div class="kakaoLogin" @click="kakaoLogin()">
                            <img src="/media/img/visitor/kakao_icon.svg" alt="" />
                            <p>카카오 아이디로 로그인</p>
                        </div>
                        <div id="naverIdLogin" style="display: none"></div>
                        <div class="naverLogin" @click="naverLogin()">
                            <img src="/media/img/visitor/naver_icon.svg" alt="" />
                            <p>네이버 아이디로 로그인</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="comment_content" v-else>
                <div class="written_comment" v-if="!isUpdateComment">
                    <p v-html="content"></p>
                    <div class="setting_area">
                        <div id="setting_icon" @click="showSetBox($event)">
                            <img src="/media/img/setting_icon.svg" class="show_select_box" />
                        </div>
                        <div class="mo_setting_show_box">
                            <p @click="updateTextBoxOn($event)"><img src="/media/img/icon_update.svg" alt="" />수정하기</p>
                            <p @click="showRemoveComment = true"><img src="/media/img/icon_delete_16px.svg" alt="" />삭제하기</p>
                        </div>
                    </div>
                </div>
                <textarea v-else type="text" maxlength="50" v-model="content" @keyup="keyEvent()"></textarea>
                <p class="comment_length">{{ content.length }}<span>/50</span></p>
                <span class="comment_btn_cancel" @click="updateTextBoxOff($event)">취소</span>
                <span class="comment_btn" v-if="!disabledUpdate" @click="insertMusicComment($event)">수정</span>
                <span class="comment_btn" style="text-align: center; filter: brightness(60%)" v-else>수정</span>
            </div>
            <ul class="music_comment_list" v-if="total < 1">
                <li class="music_comment_empty">
                    <p>아직 작성된 한줄평이 없어요.</p>
                    <p>이 노래의 첫 한줄평을 작성해주세요:)</p>
                </li>
            </ul>
            <div class="music_comment_list_box" v-else>
                <ul class="music_comment_list" v-for="(item, index) in musicCommentList" :key="index">
                    <li>
                        <span>
                            <span v-if="item.gender === '1' || item.gender === 'M' || item.gender === 'male'">
                                <img
                                    v-if="item.age === '10-19' || item.age === '10~19' || checkAge(item.birthDate) < 20"
                                    src="/media/img/visitor/men_age10.svg"
                                    width="18"
                                    height="18"
                                />
                                <img
                                    v-else-if="
                                        item.age === '20-29' ||
                                            item.age === '20~29' ||
                                            (checkAge(item.birthDate) < 30 && checkAge(item.birthDate) >= 20)
                                    "
                                    src="/media/img/visitor/men_age20.svg"
                                    width="18"
                                    height="18"
                                />
                                <img
                                    v-else-if="
                                        item.age === '30-39' ||
                                            item.age === '30~39' ||
                                            (checkAge(item.birthDate) < 40 && checkAge(item.birthDate) >= 30)
                                    "
                                    src="/media/img/visitor/men_age30.svg"
                                    width="18"
                                    height="18"
                                />
                                <img
                                    v-else-if="
                                        item.age === '40-49' ||
                                            item.age === '40~49' ||
                                            (checkAge(item.birthDate) < 50 && checkAge(item.birthDate) >= 40)
                                    "
                                    src="/media/img/visitor/men_age40.svg"
                                    width="18"
                                    height="18"
                                />
                                <img v-else src="/media/img/visitor/men_age50.svg" width="18" height="18" />
                            </span>
                            <span v-else>
                                <img
                                    v-if="item.age === '10-19' || item.age === '10~19' || checkAge(item.birthDate) < 20"
                                    src="/media/img/visitor/women_age10.svg"
                                    width="18"
                                    height="18"
                                />
                                <img
                                    v-else-if="
                                        item.age === '20-29' ||
                                            item.age === '20~29' ||
                                            (checkAge(item.birthDate) < 30 && checkAge(item.birthDate) >= 20)
                                    "
                                    src="/media/img/visitor/women_age20.svg"
                                    width="18"
                                    height="18"
                                />
                                <img
                                    v-else-if="
                                        item.age === '30-39' ||
                                            item.age === '30~39' ||
                                            (checkAge(item.birthDate) < 40 && checkAge(item.birthDate) >= 30)
                                    "
                                    src="/media/img/visitor/women_age30.svg"
                                    width="18"
                                    height="18"
                                />
                                <img
                                    v-else-if="
                                        item.age === '40-49' ||
                                            item.age === '40~49' ||
                                            (checkAge(item.birthDate) < 50 && checkAge(item.birthDate) >= 40)
                                    "
                                    src="/media/img/visitor/women_age40.svg"
                                    width="18"
                                    height="18"
                                />
                                <img v-else src="/media/img/visitor/women_age50.svg" width="18" height="18" />
                            </span>
                            <img
                                class="social_icon"
                                v-if="item.comeFrom === 'kakao'"
                                src="/media/img/visitor/icon_cmt_kakao.svg"
                                width="10"
                            />
                            <img
                                class="social_icon"
                                v-else-if="item.comeFrom === 'naver'"
                                src="/media/img/visitor/icon_cmt_naver.svg"
                                width="10"
                            />
                            <p class="music_comment_writer">{{ item.email }}</p>
                        </span>
                        <p class="music_comment_content" v-html="item.content"></p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { naverService } from '@/assets/js/naverLogin.js';
import { defineComponent } from '@vue/composition-api';
import RemoveComment from '@/components/modal/Player/RemoveComment.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import $ from 'jquery';
import Like from '@/components/modal/Player/Like.vue';
import Hate from '@/components/modal/Player/Hate.vue';
export default defineComponent({
  name: 'MusicComment-Visitor',
  data () {
    return {
      isLogin: false,
      no: 0,
      spaceInfo: {
        spaceId: '',
        spaceNm: ''
      },
      musicInfo: {
        musicId: '',
        albumImg: '',
        musicNm: '',
        artist: ''
      },
      musicCommentList: [],
      content: '',
      total: 0,
      limit: 10,
      offset: 0,
      disabledInsert: false,
      disabledUpdate: false,
      updateComment: false,
      isUpdateComment: false,
      showRemoveComment: false,
      isHyundaiCaptatial: false
    };
  },
  components: {
    RemoveComment,
    Like,
    Hate
  },
  created () {
    let hyundaicapitalSpaceList = [];
    const spaceId = this.$route.params.spaceId;
    if (window.location.host === 'www.aplayz.co.kr') {
      hyundaicapitalSpaceList = ['space_013979', 'space_013978', 'space_013977'];
    } else {
      hyundaicapitalSpaceList = ['space_003295'];
    }

    if (hyundaicapitalSpaceList.includes(spaceId)) {
      this.isHyundaiCaptatial = true;
    }
  },
  async mounted () {
    await this.getPlayingMusicInfo();
    naverService().setNaver();
    if (VueCookies.get('visitorId') != null) this.isLogin = true;
  },
  methods: {
    checkAge (birthdate) {
      // 나이 체크
      var today = new Date();
      var yyyy = today.getFullYear();
      var age = parseInt(yyyy - birthdate.substring(0, 4));
      return age;
    },
    naverLogin () {
      // 네이버 로그인
      const btn = document.getElementById('naverIdLogin').firstChild;
      btn.removeAttribute('href');
      $('#naverIdLogin')[0].children[0].click();
    },
    kakaoLogin () {
      // 카카오 로그인
      window.Kakao.Auth.login({
        success: auth => {
          this.hideSimpleLoginBox();
          this.getKakaoAccount();
        },
        fail: err => {
          console.log(err);
        }
      });
    },
    getKakaoAccount () {
      // 카카오에서 로그인정보 가져오기
      window.Kakao.API.request({
        url: '/v2/user/me',
        success: res => {
          const kakaoAccount = res.kakao_account;

          var comeFrom = 'kakao';
          var email = kakaoAccount.email;
          // var name = kakaoAccount.profile.nickname;
          var gender = kakaoAccount.gender;
          var age = kakaoAccount.age_range;
          var birthDate = kakaoAccount.birthday;

          this.insertVisitorInfo(comeFrom, email, gender, age, birthDate, name);
        },
        fail: err => {
          console.log(err);
        }
      });
    },
    insertVisitorInfo (comeFrom, email, gender, age, birthDate, name, phoneNumber, address) {
      // 방문자 정보 db등록
      axios
        .post('/api/visitor/welcomeVisitor', { comeFrom, email, gender, age, birthDate, name, phoneNumber, address })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.isLogin = true;
            var result = res.data.result;
            // 쿠키에 id저장 - 로그인 처리에 필요
            VueCookies.set('visitorId', result.id, 60 * 10);
            var musicId = this.musicInfo.musicId;
            this.getMusicCommentByUser(musicId);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    changeLike (e) {
      // 좋아요 등록 및 취소
      $(e.currentTarget).attr('src', (index, attr) => {
        this.changeUnlikeAnimation('Y');
        if (attr.match('_active.svg')) {
          VueCookies.set('visitor_like', null, 60 * 10);
          this.recordCancelLikeit();
          this.changeLikeAnimation('Y');
          return attr.replace('_active.svg', '_off.svg');
        } else {
          VueCookies.set('visitor_like', 'Y', 60 * 10);
          this.recordLikeit();
          this.changeLikeAnimation('N');
          $('#v_unlike').attr('src', (index, attr2) => {
            if (attr2.match('_active.svg')) {
              this.recordCancelDislikeit();
              return attr2.replace('_active.svg', '_off.svg');
            }
          });
          return attr.replace('_off.svg', '_active.svg');
        }
      });
    },
    changeLikeAnimation (active = 'N') {
      const likeModal = document.querySelector('.like_modal');
      const thumb = likeModal.querySelector('.thumb');
      const circleLg = likeModal.querySelector('.circle-lg');
      const dotT = likeModal.querySelector('.dot--t');
      const dotTr = likeModal.querySelector('.dot--tr');
      const dotBr = likeModal.querySelector('.dot--br');
      const dotB = likeModal.querySelector('.dot--b');
      const dotBl = likeModal.querySelector('.dot--bl');
      const dotTl = likeModal.querySelector('.dot--tl');
      if (active === 'Y') {
        thumb.classList.remove('on');
        circleLg.classList.remove('on');
        dotT.classList.remove('on');
        dotTr.classList.remove('on');
        dotBr.classList.remove('on');
        dotB.classList.remove('on');
        dotBl.classList.remove('on');
        dotTl.classList.remove('on');
      } else {
        // on class 추가
        thumb.classList.add('on');
        circleLg.classList.add('on');
        dotT.classList.add('on');
        dotTr.classList.add('on');
        dotBr.classList.add('on');
        dotB.classList.add('on');
        dotBl.classList.add('on');
        dotTl.classList.add('on');
      }
    },

    changeDisLike (e) {
      // 싫어요 등록 및 취소
      $(e.currentTarget).attr('src', (index, attr) => {
        this.changeLikeAnimation('Y');
        if (attr.match('_active.svg')) {
          VueCookies.set('visitor_like', null, 60 * 10);
          this.recordCancelDislikeit();
          this.changeUnlikeAnimation('Y');
          return attr.replace('_active.svg', '_off.svg');
        } else {
          VueCookies.set('visitor_like', 'N', 60 * 10);
          this.recordDisLikeit();
          this.changeUnlikeAnimation('N');
          $('#v_like').attr('src', (index, attr2) => {
            if (attr2.match('_active.svg')) {
              this.recordCancelLikeit();
              return attr2.replace('_active.svg', '_off.svg');
            }
          });
          return attr.replace('_off.svg', '_active.svg');
        }
      });
    },

    changeUnlikeAnimation (active = 'N') {
      const likeModal = document.querySelector('.hate_modal');
      const thumb = likeModal.querySelector('.thumb');
      const circleLg = likeModal.querySelector('.circle-lg');
      const dotT = likeModal.querySelector('.dot--t');
      const dotTr = likeModal.querySelector('.dot--tr');
      const dotBr = likeModal.querySelector('.dot--br');
      const dotB = likeModal.querySelector('.dot--b');
      const dotBl = likeModal.querySelector('.dot--bl');
      const dotTl = likeModal.querySelector('.dot--tl');

      if (active === 'Y') {
        thumb.classList.remove('on');
        circleLg.classList.remove('on');
        dotT.classList.remove('on');
        dotTr.classList.remove('on');
        dotBr.classList.remove('on');
        dotB.classList.remove('on');
        dotBl.classList.remove('on');
        dotTl.classList.remove('on');
      } else {
        // on class 추가
        thumb.classList.add('on');
        circleLg.classList.add('on');
        dotT.classList.add('on');
        dotTr.classList.add('on');
        dotBr.classList.add('on');
        dotB.classList.add('on');
        dotBl.classList.add('on');
        dotTl.classList.add('on');
      }
    },
    recordLikeit () {
      // 좋아요 등록
      const spaceId = this.$route.params.spaceId;
      const musicId = this.musicInfo.musicId;
      let visitorId = null;
      if (VueCookies.get('visitorId') != null) visitorId = VueCookies.get('visitorId');

      axios.post('/api/visitor/recordLikeit', { spaceId, musicId, visitorId }).catch(err => {
        console.log(err);
      });
    },
    recordCancelLikeit () {
      // 좋아요 취소
      const spaceId = this.$route.params.spaceId;
      const musicId = this.musicInfo.musicId;

      axios.post('/api/visitor/recordCancelLikeit', { spaceId, musicId }).catch(err => {
        console.log(err);
      });
    },
    recordDisLikeit () {
      // 싫어요 등록
      const spaceId = this.$route.params.spaceId;
      const musicId = this.musicInfo.musicId;
      let visitorId = null;
      if (VueCookies.get('visitorId') != null) visitorId = VueCookies.get('visitorId');

      axios.post('/api/visitor/recordDislikeit', { spaceId, musicId, visitorId }).catch(err => {
        console.log(err);
      });
    },
    recordCancelDislikeit () {
      // 싫어요 취소
      const spaceId = this.$route.params.spaceId;
      const musicId = this.musicInfo.musicId;

      axios.post('/api/visitor/recordCancelDislikeit', { spaceId, musicId }).catch(err => {
        console.log(err);
      });
    },
    keyEvent () {
      this.content.length === 0 ? (this.disabledUpdate = true) : (this.disabledUpdate = false);
    },
    updateCommentMo (musicId, content) {
      this.$emit('updateComment', musicId, content);
    },
    insertTextBoxOff (e) {
      const textBox = e.currentTarget.parentElement;
      textBox.classList.remove('on');
      textBox.parentElement.children[3].classList.remove('on');
      this.content = '';
    },
    updateTextBoxOff (e) {
      const musicId = this.musicInfo.musicId;
      this.isUpdateComment = false;
      e.currentTarget.parentElement.classList.remove('on');
      this.getMusicCommentByUser(musicId);
    },
    updateTextBoxOn (e) {
      this.isUpdateComment = true;
      e.currentTarget.parentElement.parentElement.parentElement.parentElement.classList.add('on');
    },
    hideSimpleLoginBox () {
      if ($('.simple_login').css('bottom') === '0px') {
        $('.simple_login').css({ bottom: '-100%', transition: 'all 1s' });
      }
    },
    hideSetBoxMo () {
      if ($('.mo_setting_show_box').css('bottom') === '0px') {
        $('.mo_setting_show_box').css({ bottom: '-100%', transition: 'all 1s' });
      }
    },
    showSetBox (e) {
      $(e.currentTarget.parentElement)
        .find('#setting_icon')
        .siblings('.setting_show_box')
        .fadeToggle();
      $('.mo_setting_show_box').css({ bottom: 0, transition: 'all 0.5s' });
    },
    textareaClickEvent (e) {
      if (this.isLogin) {
        e.currentTarget.parentElement.classList.add('on');
      } else {
        console.log('click');
        $(e.currentTarget.parentElement)
          .find('#setting_icon')
          .siblings('.setting_show_box')
          .fadeToggle();
        $('.simple_login').css({ bottom: 0, transition: 'all 0.5s' });
      }
    },
    removeMusicComment () {
      // 음원에 대해 작성된 한줄평 삭제
      const no = this.no;

      axios
        .post('/api/visitor/deleteShortComment', { no })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.musicCommentList = result.listResult;
            this.total = result.total;
            this.updateComment = false;
            this.content = '';
            this.no = 0;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    insertMusicComment (e) {
      // 음원에 대한 한줄평 등록
      this.disabledInsert = true;
      e.currentTarget.parentElement.classList.remove('on');

      const no = this.no;
      const musicId = this.musicInfo.musicId;
      const userId = VueCookies.get('visitorId');
      const spaceId = this.$route.params.spaceId;
      const content = this.content;

      axios
        .post('/api/visitor/recordShortComment', { no, musicId, userId, spaceId, content })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.musicCommentList = result.listResult;
            this.total = result.total;
            this.isUpdateComment = false;
            this.updateComment = true;
            this.getMusicCommentByUser(musicId);
            this.disabledInsert = false;
          }
        })
        .catch(err => {
          this.disabledInsert = false;
          console.log(err);
        });
    },
    getMusicCommentByUser (musicId) {
      // 음원에 대해 로그인한 방문자가 작성한 한줄평 가져오기
      const spaceId = this.$route.params.spaceId;
      const visitorInfo = { id: VueCookies.get('visitorId') };
      axios
        .post('/api/visitor/getMyMusicComment', { musicId, spaceId, visitorInfo })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (result != null) {
              this.no = result.no;
              this.content = result.content;
            }
            result === null ? (this.updateComment = false) : (this.updateComment = true);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getPlayingMusicInfo () {
      // 연결된 스페이스에서 가장 최근에 플레이된 음원정보 가져오기
      const spaceId = this.$route.params.spaceId;
      this.$store.commit('setSpaceInfo', spaceId);
      const limit = this.limit;
      const offset = this.offset;

      await axios
        .post('/api/visitor/getPlayingSound', { spaceId, limit, offset })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.spaceInfo.spaceId = result.spaceInfo.spaceId;
            this.spaceInfo.spaceNm = result.spaceInfo.spaceNm;
            this.musicInfo.musicId = result.musicInfo.musicId;
            // 세션스토리지에 musicId 저장 및 like icon 조정
            if (VueCookies.get('visitor_musicId') === null) {
              VueCookies.set('visitor_musicId', result.musicInfo.musicId, 60 * 10);
            } else {
              if (VueCookies.get('visitor_musicId') === result.musicInfo.musicId) {
                if (VueCookies.get('visitor_like') === 'Y') {
                  document.getElementById('v_like').src = '/media/img/like_icon_active.svg';
                  document.getElementById('v_unlike').src = '/media/img/hate_icon_off.svg';
                } else if (VueCookies.get('visitor_like') === 'N') {
                  document.getElementById('v_like').src = '/media/img/like_icon_off.svg';
                  document.getElementById('v_unlike').src = '/media/img/hate_icon_active.svg';
                } else {
                  document.getElementById('v_like').src = '/media/img/like_icon_off.svg';
                  document.getElementById('v_unlike').src = '/media/img/hate_icon_off.svg';
                }
              } else {
                VueCookies.set('visitor_musicId', result.musicInfo.musicId, 60 * 10);
                VueCookies.set('visitor_like', null);
                document.getElementById('v_like').src = '/media/img/like_icon_off.svg';
                document.getElementById('v_unlike').src = '/media/img/hate_icon_off.svg';
              }
            }
            result.musicInfo.albumImg.search('/media/img/') < 0
              ? (this.musicInfo.albumImg = result.musicInfo.albumImg)
              : (this.musicInfo.albumImg = result.musicInfo.albumImg);
            this.musicInfo.musicNm = result.musicInfo.musicNm;
            this.musicInfo.artist = result.musicInfo.artist;
            this.musicCommentList = result.musicCommentList;
            this.total = result.musicCommentTotalCnt;

            if (VueCookies.get('visitorId') != null) this.getMusicCommentByUser(this.musicInfo.musicId);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
}

@media all and (max-width: 500px) {
  .modal_bottom_btn button {
    margin-bottom: 0;
  }
}

@media all and (min-width: 501px) {
  .mo_space_header {
    display: none;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
